<template>
  <div class="mall">
    <div class="mall-t">
      <div class="usePoints">
        <div class="item-t">
          <count-to
            :start-val="0"
            :end-val="pointsData.availableTokenPoint || 0"
            :duration="1000"
            class="card-panel-num"
          />
        </div>
        <div class="item-b">甜蜜值</div>
      </div>
    </div>
    <div class="mall-b">
      <div class="mall-title">
        <div
          :class="['mall-title-l', { active: titleIndex == 0 }]"
          @click="selectTitle(0)"
        >
          收支明细
        </div>
        <div
          :class="['mall-title-r', { active: titleIndex == 1 }]"
          @click="selectTitle(1)"
        >
          甜蜜值排行榜
        </div>
      </div>
      <div class="mall-b-body">
        <div class="mall-b-body-l" v-if="titleIndex == 0">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :immediateCheck="false"
            :finishedText="finishedText"
            ref="list"
          >
            <div v-for="(item, index) in listData" :key="index">
              <div
                class="oItem"
                v-if="
                  (item.integralPoint != 0 && activeCode == 1) ||
                  (item.tokenPoint != 0 && activeCode == 2)
                "
              >
                <div class="oItem-l">
                  <div class="title">
                    {{ pointTypeDataMap[item.recordType] }}
                  </div>
                  <div class="time">{{ item.insertTime }}</div>
                </div>
                <div class="oItem-r">
                  <div
                    class="receive"
                    style="color: #347eff"
                    v-if="activeCode == 1"
                  >
                    {{ item.operationType == 0 ? "-" : "+" }}
                  </div>
                  <div
                    class="receive"
                    style="color: #347eff"
                    v-if="activeCode == 2"
                  >
                    {{ item.operationType == 0 ? "-" : "+" }}
                  </div>
                  <div
                    class="point"
                    style="color: #347eff"
                    v-if="activeCode == 1"
                  >
                    {{ item.integralPoint }}
                  </div>
                  <div
                    class="point"
                    style="color: #347eff"
                    v-if="activeCode == 2"
                  >
                    {{ item.tokenPoint }}
                  </div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
        <div class="mall-b-body-r" v-else>
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :immediateCheck="false"
            :finishedText="finishedText"
            ref="list"
          >
            <div class="item" v-for="(item, index) in listData1" :key="index">
              <div class="oItem">
                <div class="oItemRank">
                  <img v-if="index === 0" src="./img/gold.png" alt="" />
                  <img v-else-if="index === 1" src="./img/silver.png" alt="" />
                  <img v-else-if="index === 2" src="./img/copper.png" alt="" />
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="oItemName">
                  <div class="img">
                    <img
                      :src="
                        item.avatar
                          ? item.avatar
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.userName }}</div>
                </div>
                <div class="oItemPoint">
                  <div class="pointNum">{{ item.totalIntegralPoint }}</div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getGoodsListUrl,
  pointsUrl,
  pointRankListUrl,
  volunteerUrl,
  pointsList,
} from "./api.js";
import { gloabalCount } from "@/utils/common.js";
import CountTo from "vue-count-to";
import { pointTypeDataMap } from "./map.js";
export default {
  name: "mall",
  components: { CountTo },
  data() {
    return {
      pointTypeDataMap,
      titleIndex: 0,
      pointsData: {
        totlePointCount: 0,
        availablePoint: 0,
      },
      finishedText: "没有更多了",
      finished: true,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      curTab: {
        label: "个人甜蜜值",
        value: "1",
      },
      listData1: [],
      activeCode: 2,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {
    this.getPoints();
    this.onRefresh();
  },
  methods: {
    async getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.post(
        `${pointsUrl}`,
        this.$qs.stringify(params),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      if (res.code === 200) {
        if (res.data) {
          this.pointsData = res.data;
        }
      } else {
      }
    },
    async getPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
        pointType: this.activeCode, // 1 积分 2积分
      };
      let res = await this.$axios.get(`${pointsList}`, { params });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          this.listData.push(item);
        });
      } else {
        this.finished = true;
      }
    },
    onRefresh() {
      this.listData = [];
      this.listData1 = [];
      this.finishedText = "没有更多了";
      this.requestData.curPage = 1;
      this.finished = true;
      this.onLoad();
    },
    async getRankPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
        pointType: this.curTab.value,
      };
      let res = await this.$axios.get(
        `${pointRankListUrl}`,
        { params },
        {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        }
      );
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          if (item.avatar) {
            item.avatar = this.$handleImg(96, 96, item.avatar);
          }
          this.listData1.push(item);
        });
      } else {
      }
    },
    onLoad() {
      if (this.titleIndex == 0) {
        this.getPointsList();
      } else {
        this.getRankPointsList();
      }
    },
    selectTitle(data) {
      this.titleIndex = data;
      this.onRefresh();
    },
  },
};
</script>
<style lang="less" scoped>
.mall {
  min-height: 100vh;
  .mall-t {
    height: 338px;
    background: url("./img/pointBack.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    position: relative;
    .usePoints {
      position: absolute;
      height: 154px;
      text-align: center;
      left: 104px;
      bottom: 50px;
    }
    .item-t {
      font-size: 80px;
      color: #16113b;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .item-b {
      font-size: 30px;
    }
  }
  .mall-b {
    padding: 0 38px;
    .mall-title {
      padding: 0 40px;
      display: flex;
      border-bottom: 2px solid #d6e1f5;
      .mall-title-l,
      .mall-title-r {
        padding: 20px 0 34px;
        font-size: 28px;
        font-weight: 400;
        color: #16113b;
        line-height: 40px;
        margin-right: 76px;
      }
      .active {
        font-size: 30px;
        font-weight: 700;
        color: #16113b;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 54px;
          height: 8px;
          background: #367bf4;
          border-radius: 2px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
    .mall-b-body {
      .mall-b-body-l {
        padding-top: 40px;
        .oItem {
          background: #fff;
          padding: 28px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100px;
          border-bottom: 2px solid rgba(0, 0, 0, 0.06);
          .oItem-l {
            font-size: 28px;
            line-height: 48px;
            color: #333;
            .time {
              color: rgba(0, 0, 0, 0.25);
            }
          }
          .oItem-r {
            display: flex;
            align-items: center;
            color: rgba(0, 126, 255, 1);
            font-size: 36px;
            font-weight: bold;
            .receive {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 4px;
              font-family: "Courier New", Courier, monospace;
            }
            .point {
              color: #347eff;
            }
          }
        }
      }
      .mall-b-body-r {
        padding-top: 70px;
        .oItem {
          display: flex;
          margin-bottom: 26px;
          align-items: center;
          justify-content: center;
          .oItemRank {
            position: relative;
            flex: 2;
            font-size: 28px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
            line-height: 82px;
            > img {
              position: absolute;
              top: 51%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 55px;
              height: 62px;
              vertical-align: middle;
              z-index: -1;
            }
          }
          .oItemName {
            flex: 5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .img {
              width: 82px;
              height: 82px;
              border: 1px solid rgba(0, 126, 255, 1);
              margin: 0 16px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .name {
              width: calc(100% - 120px);
              text-overflow: ellipsis;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 28px;
              color: rgba(102, 102, 102, 1);
            }
          }
          .oItemPoint {
            flex: 3;
            .pointNum {
              text-align: center;
              font-size: 40px;
              font-weight: 600;
              color: #317dff;
            }
            .pointImg {
              width: 40px;
              height: 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
