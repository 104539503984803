import { mapHelper } from "@/utils/common.js";
const pointType = [
  { key: 0, value: "甜蜜值扫码扣除" },
  { key: 1, value: "活动签到" },
  { key: 2, value: "扫码获取" },
  { key: 3, value: "未来心愿" },
  { key: 4, value: "社区共建" },
  { key: 5, value: "公益捐赠" },
  { key: 6, value: "活动发布" },
  { key: 7, value: "运动打卡" },
  { key: 8, value: "社区达人申请" },
  { key: 9, value: "甜蜜值商城兑换" },
  { key: 11, value: "用户注册" },
  { key: 12, value: "每日签到" },
  { key: 13, value: "点赞任务" },
  { key: 14, value: "评论任务" },
  { key: 15, value: "完善资料" },
  { key: 16, value: "与小和微信互动" },
  { key: 17, value: "超话活动" },
  { key: 18, value: "党建在线学习" },
  { key: 19, value: "邻里公约" },
  { key: 20, value: "访问甜蜜值商城" },
  { key: 21, value: "访问小和超话" },
  { key: 22, value: "访问小和广播站" },
  { key: 23, value: "访问活动广场" },
  { key: 24, value: "组合任务额外加分" },
  { key: 25, value: "社群场地预约" },
  { key: 26, value: "商圈扫码" },
  { key: 27, value: "时间银行签到" },
  { key: 99, value: "甜蜜值转赠" },
];

const statusOps = [
  {
    key: 1,
    value: "待核销",
  },
  {
    key: 3,
    value: "已完成",
  },
];

const pointTypeData = [
  { value: 0, label: "扫码扣除" },
  { value: 1, label: "活动签到" },
  { value: 2, label: "扫码获取" },
  { value: 3, label: "未来心愿" },
  { value: 4, label: "社区共建" },
  { value: 5, label: "公益捐赠" },
  { value: 6, label: "活动发布" },
  { value: 7, label: "运动打卡" },
  { value: 8, label: "社区达人申请" },
  { value: 9, label: "商城兑换" },
  { value: 11, label: "用户注册" },
  { value: 12, label: "每日签到" },
  { value: 13, label: "点赞任务" },
  { value: 14, label: "评论任务" },
  { value: 15, label: "完善资料" },
  { value: 16, label: "与小和微信互动" },
  { value: 17, label: "超话活动" },
  { value: 18, label: "党建在线学习" },
  { value: 19, label: "邻里公约" },
  { value: 20, label: "访问商城" },
  { value: 21, label: "访问小和超话" },
  { value: 22, label: "访问小和广播站" },
  { value: 23, label: "访问活动广场" },
  { value: 24, label: "组合任务额外加分" },
  { value: 25, label: "社群场地预约" },
  { value: 26, label: "商圈扫码" },
  { value: 27, label: "时间银行签到" },
  { value: 28, label: "运动打卡" },
  { value: 99, label: "转赠" },
  { value: 105, label: "申请志愿者" },
];

const { map: pointTypeDataMap, setOps: pointTypeDataOps } =
  mapHelper.setMap(pointTypeData);

export { pointType, statusOps, pointTypeDataMap };
